<template>
  <v-row>
    <v-col md="12" class="gray-background-ss">
      <table class="width-100">
        <tr v-if="engineer.country_name">
          <td
            valign="top"
            style="width: 100px"
            class="font-size-18 text-capitalize font-weight-500"
          >
            <template v-if="engineer.country_name == 'singapore'">
              Street 1
            </template>
            <template v-else>Full Address</template>
          </td>
          <td class="font-size-18 text-capitalize">
            <!-- {{ engineer.address_line_1 || " - " }} -->

            <template v-if="engineer.address_line_1">{{
              engineer.address_line_1
            }}</template>
            <template v-else><em class="text-muted">no street 1</em></template>
          </td>
        </tr>
        <template v-if="engineer.country_name == 'singapore'">
          <tr>
            <td class="font-size-18 text-capitalize font-weight-500">
              Street 2
            </td>
            <td class="font-size-18 text-capitalize">
              <!-- {{ engineer.address_line_2 || " - " }} -->
              <template v-if="engineer.address_line_2">{{
                engineer.address_line_2
              }}</template>
              <template v-else
                ><em class="text-muted">no street 2</em></template
              >
            </td>
          </tr>
          <tr>
            <td class="font-size-18 text-capitalize font-weight-500">
              Unit no.
            </td>
            <td class="font-size-18 text-capitalize">
              <!--  {{ engineer.unit_number || " - " }} -->
              <template v-if="engineer.unit_number">{{
                engineer.unit_number
              }}</template>
              <template v-else><em class="text-muted">no unit no</em></template>
            </td>
          </tr>
          <tr>
            <td class="font-size-18 text-capitalize font-weight-500">
              Postal Code
            </td>
            <td class="font-size-18 text-capitalize">
              <!-- {{ engineer.postal_code || " - " }} -->
              <template v-if="engineer.postal_code">{{
                engineer.postal_code
              }}</template>
              <template v-else
                ><em class="text-muted">no postal code</em></template
              >
            </td>
          </tr>
        </template>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500">Country</td>
          <td class="font-size-18 text-capitalize">
            <!-- {{ getCountryName || " - " }} -->
            <template v-if="engineer.country_name">{{
              engineer.country_name
            }}</template>
            <template v-else><em class="text-muted">no country</em></template>
            <!-- <template v-if="getCountryName">{{
                getCountryName
              }}</template>
              <template v-else
                ><em class="text-muted">no country</em></template
              > -->
          </td>
        </tr>
        <tr>
          <td class="font-size-18 text-capitalize font-weight-500" valign="top">
            Zone Territories
          </td>
          <td class="font-size-18 text-capitalize">
            <template v-if="engineer.zone_territory.length">
              <v-simple-table
                class="inner-simple-table custom-border-top certs-table"
                fixed-header
              >
                <template v-slot:default>
                  <tbody>
                    <template v-if="engineer.zone_territory.length >= 0">
                      <tr
                        v-for="(row, index) in engineer.zone_territory"
                        :key="index"
                        :class="{ 'orange_lighten-5': index % 2 == 0 }"
                      >
                        <td class="font-size-18 text-capitalize">
                          {{ row }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td :colspan="6">
                          <p
                            class="m-0 row-not-found text-center font-weight-500 font-size-16"
                          >
                            Sorry! No zone territory(s) Found.
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <template v-else
              ><em class="text-muted">no zone territories</em></template
            >
          </td>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  name: "engineer-address",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      pageLoading: true,
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  computed: {
    getCountryName() {
      if (
        this.lodash.isEmpty(this.engineer) === false &&
        this.lodash.isEmpty(this.engineer.country) === false
      ) {
        return this.engineer.country.name;
      }
      return null;
    },
  },
};
</script>
