<template>
  <div id="app">
    <v-app id="inspire">
      <v-container style="max-width: 800px">
        <v-timeline dense v-if="objectLength() > 0">
          <template v-for="(item, i) in logs">
            <span :key="i">
              <v-timeline-item class="align-center" dot small color="green">
                <span class="text-h6 text-capitalize">
                  <template v-if="i == 'today' || i == 'yesterday'">
                    {{ i }}
                  </template>
                  <template v-else>
                    {{ formatDate(i) }}
                  </template>
                </span>
              </v-timeline-item>
              <template v-for="(row, ci) in item">
                <v-timeline-item color="grey" small :key="ci">
                  <span slot="opposite">{{ formatTime(row?.datetime) }}</span>
                  <v-card class="elevation-2" width="300">
                    <v-card-text>
                      <table class="width-100">
                        <tr>
                          <td class="font-size-16 py-1 font-weight-400">
                            Device
                          </td>
                          <td class="font-size-16 py-1 font-weight-500">
                            {{ row?.device }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-16 py-1 font-weight-400">
                            Platform
                          </td>
                          <td class="font-size-16 py-1 font-weight-500">
                            {{ row?.platform }} - {{ row?.platform_version }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-16 py-1 font-weight-400">
                            Browser
                          </td>
                          <td class="font-size-16 py-1 font-weight-500">
                            {{ row?.browser }} - {{ row?.browser_version }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-16 py-1 font-weight-400">
                            Robot
                          </td>
                          <td class="font-size-16 py-1 font-weight-500">
                            <template v-if="row.robot">Yes</template>
                            <template v-else>No</template>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </template>
            </span>
          </template>
        </v-timeline>
        <v-col class="text-center border-top" v-else>
          <p class="m-0 py-15 row-not-found text-center">
            <img
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image"
            />
            Uhh... There are no detail at the moment.
          </p>
        </v-col>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "login-log",
  props: {
    logs: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    user: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  watch: {
    logs: {
      deep: true,
      immediate: true,
      handler(param) {
        this.loglist = param;
        /*  if (param && param.length > 0) {
          let temp = this.lodash.cloneDeep(param);
          temp.sort(function compare(a, b) {
            var dateA = new Date(b.datetime);
            var dateB = new Date(a.datetime);
            return dateA - dateB;
          });
          this.loglist = this.lodash.cloneDeep(temp);
        } */
      },
    },
  },
  data() {
    return {
      loglist: [],
    };
  },
  methods: {
    objectLength() {
      // Use Object.keys() to get an array of keys in the object
      return Object.keys(this.logs).length;
    },
  },
};
</script>
