<template>
  <div>
    <DetailTemplate
      :custom-class="'engineer-detail detail-page'"
      v-if="getPermission('engineer:view')"
    >
      <template v-slot:sidebar-detail>
        <div>
          <Status
            style="margin: 0 !important"
            :status="
              lodash.isEmpty(engineerArr) === false
                ? engineerArr.activated
                : false
            "
          ></Status>
          <template
            v-if="engineerArr && engineerArr.role && engineerArr.role.id == 2"
          >
            <v-chip label outlined text-color="" dense class="ml-2" color="blue"
              >Technician</v-chip
            >
          </template>
          <template v-if="engineerArr && engineerArr.is_driver">
            <v-chip label outlined text-color="" dense class="ml-2" color="red">
              Driver</v-chip
            >
          </template>
          <v-chip
            label
            text-color="white"
            dense
            :color="engineerArr.checkin_in_out == 'checkout' ? 'green' : 'red'"
            class="mr-1 ml-2"
          >
            <template v-if="engineerArr.checkin_in_out == 'checkout'">
              Online</template
            >
            <template v-else> Offline</template>
          </v-chip>
        </div>
        <div class="mt-5">
          <PersonalInfo :engineer="engineerArr"></PersonalInfo>
        </div>
        <div class="mt-5">
          <v-icon
            style="
              font-size: 15px;
              margin-bottom: 15px !important;
              display: inline-block;
            "
            :color="attachment_url ? 'green' : 'red'"
            class="mt-1 mb-1 ml-2"
            small
            >mdi-circle</v-icon
          >
          <v-btn
            depressed
            class="mx-2 custom-bold-button mb-2"
            @click="signatureDialog = true"
          >
            <v-icon color="cyan" size="20" class="pr-2"
              >mdi-signature-text</v-icon
            >
            Signature
          </v-btn>
          <div>
            <v-icon
              style="
                font-size: 15px;
                margin-bottom: 15px !important;
                display: inline-block;
              "
              :color="engineerArr?.user?.email_signature ? 'green' : 'red'"
              class="mt-1 mb-1 ml-2"
              small
              >mdi-circle</v-icon
            >

            <v-btn
              depressed
              class="mx-2 black--text mb-2"
              color=""
              @click="emailSignature()"
            >
              <v-icon color="cyan" size="20" class="pr-2"
                >mdi-email-edit</v-icon
              >
              Email Signtaure
            </v-btn>
          </div>
          <v-btn
            depressed
            outlined
            class="mx-2 white--text"
            color="cyan"
            @click="resetPasswordDialog = true"
          >
            <v-icon size="20" class="pr-2">mdi-lock-reset</v-icon>
            Reset Password
          </v-btn>
        </div>
      </template>
      <template v-slot:header-title>
        <h1 class="form-title margin-auto">
          {{ engineerArr.full_name }}
        </h1>
      </template>
      <template v-slot:header-action>
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="red"
          depressed
          :to="
            getDefaultRoute('profile.timeSheet', {
              query: { id: engineerArr ? engineerArr.id : 0 },
            })
          "
        >
          <v-icon left>mdi-fingerprint</v-icon> Attendance
        </v-btn>

        <template v-if="getPermission('engineer:update')">
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            depressed
            :to="
              getDefaultRoute('engineer.update', {
                param: { id: engineerArr ? engineerArr.id : 0 },
              })
            "
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </template>
        <v-btn
          v-on:click="goBack"
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
        >
          <v-icon left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </template>
      <template v-slot:body>
        <v-container fluid class="px-0">
          <v-tabs
            v-model="engineerTab"
            background-color="transparent"
            centered
            color="cyan"
            fixed-tabs
            class="custom-tab-transparent sticky-tab-0"
          >
            <v-tab
              class="font-size-16 font-weight-600 px-2"
              :href="'#personal-info'"
            >
              <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
              Basic Info
            </v-tab>
            <v-tab
              class="font-size-16 font-weight-600 px-2"
              :href="'#siganture'"
              v-if="false"
            >
              <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
              Signature
            </v-tab>
            <!-- <v-tab class="font-size-16 font-weight-600 px-2" href="#delivery">
              <v-icon color="grey darken-4" left>mdi-truck</v-icon>
              Delivery Summary
            </v-tab> -->
            <v-tab class="font-size-16 font-weight-600 px-2" href="#visits">
              <v-icon color="grey darken-4" left>mdi-notebook-check</v-icon>
              Visits
            </v-tab>
            <v-tab
              class="font-size-16 font-weight-600 px-2"
              :href="'#leave-update'"
              v-if="false"
            >
              <v-icon color="grey darken-4" left>mdi-key</v-icon>
              Leave Update
            </v-tab>
            <v-tab
              class="font-size-16 font-weight-600 px-2"
              :href="'#attachments'"
            >
              <v-icon color="grey darken-4" left>mdi-file-multiple</v-icon>
              Documents
            </v-tab>
            <v-tab
              class="font-size-16 font-weight-600 px-2"
              :href="'#login-detail'"
            >
              <v-icon color="grey darken-4" left>mdi-credit-card</v-icon>
              Login Log
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-2" :href="'#history'">
              <v-icon color="grey darken-4" left>history</v-icon>
              History
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="engineerTab" class="custom-min-height-60vh">
            <v-tab-item :value="'personal-info'">
              <v-row class="justify-center">
                <v-col md="6">
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4 h-100"
                  >
                    <v-card-title class="headline grey lighten-4 py-1">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                      >
                        Emergency Contact</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16 py-0">
                      <EmergencyContact
                        :engineer="engineerArr"
                      ></EmergencyContact>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="6">
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4 h-100"
                  >
                    <v-card-title class="headline grey lighten-4 py-1">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                      >
                        Address</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16 py-0">
                      <EngineerAddress :engineer="engineerArr" />
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="12">
                  <v-card
                    flat
                    class="custom-grey-border remove-border-radius mb-4 h-100"
                  >
                    <v-card-title class="headline grey lighten-4 py-1">
                      <span
                        class="font-weight-700 custom-headline color-custom-blue font-size-16"
                      >
                        Certificates</span
                      >
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-simple-table
                        class="inner-simple-table custom-border-top certs-table"
                        fixed-header
                      >
                        <template v-slot:default>
                          <thead
                            style="
                              background-color: rgb(243, 246, 249);
                              color: rgb(36, 50, 109);
                            "
                          >
                            <tr class="custom-border-bottom">
                              <td
                                class="font-size-16 font-weight-700"
                                style="width: 10%"
                              >
                                Type
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                style="width: 20%"
                              >
                                Name
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                style="width: 15%"
                              >
                                Start Date
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                style="width: 15%"
                              >
                                Expire Date
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                style="width: 15%"
                              >
                                Reminder Date
                              </td>
                              <td
                                class="font-size-16 font-weight-700"
                                style="width: 15%"
                              >
                                Attachment
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <template
                              v-if="engineerArr?.certificates?.length >= 0"
                            >
                              <tr
                                v-for="(row, index) in engineerArr.certificates"
                                :key="index"
                                :class="{ 'orange_lighten-5': index % 2 == 0 }"
                              >
                                <td
                                  class="font-size-18 text-capitalize font-weight-600"
                                >
                                  {{ row.type.toUpperCase() }}
                                </td>

                                <td
                                  class="py-2 font-size-18 text-capitalize font-weight-600"
                                >
                                  <v-chip
                                    v-if="row?.certificate_name"
                                    small
                                    :color="row.color"
                                    label
                                    outlined
                                    :text-color="row?.color ? '' : 'black'"
                                  >
                                    {{ row?.certificate_name }}
                                  </v-chip>
                                </td>
                                <td
                                  class="py-2 font-size-18 text-capitalize font-weight-600"
                                >
                                  <template v-if="row.start_date">{{
                                    formatDate(row.start_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text-muted"
                                      >no start date</em
                                    ></template
                                  >
                                </td>
                                <td
                                  class="py-2 font-size-18 text-capitalize font-weight-600"
                                >
                                  <template v-if="row.expire_date">{{
                                    formatDate(row.expire_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text-muted"
                                      >no expire date</em
                                    ></template
                                  >
                                </td>

                                <td
                                  class="py-2 font-size-18 text-capitalize font-weight-600"
                                >
                                  <template v-if="row.reminder_date">{{
                                    formatDate(row.reminder_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text-muted"
                                      >no reminder date</em
                                    ></template
                                  >
                                </td>
                                <td
                                  class="py-0 font-size-18 text-capitalize font-weight-600"
                                >
                                  <v-btn
                                    v-if="row?.doc_file?.url"
                                    v-on:click="doAction(row, 'download')"
                                    icon
                                    depressed
                                    color="blue darken-4"
                                    class="mr-2"
                                    ><v-icon medium>mdi-download</v-icon></v-btn
                                  >
                                  <em class="text-muted" v-else
                                    >no attachment</em
                                  >
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td :colspan="6">
                                  <p
                                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                  >
                                    Sorry! No Certificates(s) Found.
                                  </p>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-expansion-panels v-if="false" v-model="panel" inset>
                  <v-expansion-panel>
                    <v-expansion-panel-header class=""
                      ><h2
                        class="margin-auto color-custom-blue font-weight-600"
                      >
                        Personal Info
                      </h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <PersonalInfo :engineer="engineerArr"></PersonalInfo>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="
                      engineerArr.allow_leave &&
                      engineerArr.leaves &&
                      engineerArr?.leaves?.length > 0
                    "
                  >
                    <v-expansion-panel-header class=""
                      ><h2
                        class="margin-auto color-custom-blue font-weight-600"
                      >
                        Leaves
                      </h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <table width="100%">
                        <tr>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            Leave
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            Used
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            Allowed
                          </td>
                        </tr>
                        <tr
                          v-for="(leave, index) in engineerArr.leaves"
                          :key="index"
                        >
                          <td
                            class="pb-2 font-size-18 font-weight-500 text-capitalize"
                          >
                            {{ leave.leave_title }}
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-500 text-capitalize"
                          >
                            {{ leave.leave_used }}
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-500 text-capitalize"
                          >
                            {{ leave.leave_allowed }}
                          </td>
                        </tr>
                      </table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><h2
                        class="margin-auto color-custom-blue font-weight-600"
                      >
                        Emergency Contact
                      </h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <EmergencyContact
                        :engineer="engineerArr"
                      ></EmergencyContact>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      ><h2
                        class="margin-auto color-custom-blue font-weight-600"
                      >
                        Address
                      </h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <EngineerAddress :engineer="engineerArr" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- <v-expansion-panel>
                    <v-expansion-panel-header
                      ><h2 class="margin-auto color-custom-blue font-weight-600">
                        Color
                      </h2></v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <Color :engineer="engineerArr"></Color>
                    </v-expansion-panel-content>
                  </v-expansion-panel> -->
                </v-expansion-panels>
              </v-row>
            </v-tab-item>
            <v-tab-item :value="'siganture'">
              <br />
              <v-container class="ml-4" v-if="false">
                <v-layout :key="attachment_url" style="max-width: 50%">
                  <v-flex md6>
                    <v-img
                      contain
                      v-if="attachment_url"
                      :lazy-src="$defaultImage"
                      :src="attachment_url"
                    ></v-img>
                    <v-img
                      v-else
                      contain
                      :lazy-src="$defaultImage"
                      :src="$defaultImage"
                    ></v-img>
                    <br />
                    <v-btn
                      v-on:click="select_file()"
                      color="cyan white--text"
                      class="custom-bold-button"
                      label
                      >Change</v-btn
                    >
                    <v-file-input
                      ref="select-file"
                      class="d-none"
                      accept="image/png, image/jpeg, image/jpg"
                      v-model="signature"
                      dense
                      v-on:change="on_file_change($event)"
                      hide-details
                    ></v-file-input>
                  </v-flex>
                  <v-flex md6> </v-flex>
                </v-layout>
                <br />
                <v-layout
                  class="signature-pad"
                  id="signature-pad"
                  style="max-width: 50%"
                >
                  <div
                    ref="engineer_signature_div"
                    style="width: 280px"
                    class="custom-border-grey-dashed signature-pad-wrap"
                  >
                    <canvas
                      ref="engineer_signature"
                      style="width: 280px; height: 120px !important"
                    ></canvas>
                  </div>
                  <hr />
                  <div style="position: relative">
                    <v-btn
                      style="position: absolute; bottom: 0px; right: -14px"
                      content="Click here to clear signature"
                      v-tippy="{ arrow: true, placement: 'top' }"
                      icon
                      small
                      v-on:click="customer_signature.clear()"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </div> </v-layout
                ><br />
                <v-layout style="max-width: 50%">
                  <v-btn
                    v-on:click="submitSignature"
                    color="cyan white--text"
                    class="custom-bold-button"
                    :disabled="valid_signature"
                    >Submit</v-btn
                  >
                </v-layout>
              </v-container>
            </v-tab-item>
            <v-tab-item :value="'emergency-contact'">
              <EmergencyContact :engineer="engineerArr"></EmergencyContact>
            </v-tab-item>

            <v-tab-item :value="'engineer-address'">
              <EngineerAddress :engineer="engineerArr" />
            </v-tab-item>

            <v-tab-item :value="'color'">
              <Color :engineer="engineerArr"></Color>
            </v-tab-item>

            <v-tab-item
              v-if="getPermission('equipment:view')"
              :value="'history'"
            >
              <InternalHistoryDetail
                type="engineer"
                :type_id="engineerArr.id"
              ></InternalHistoryDetail>
            </v-tab-item>
            <v-tab-item :value="'login-detail'">
              <LoginLog :user="user" :logs="loginLogs"></LoginLog>
            </v-tab-item>
            <v-tab-item value="visits">
              <VisitInternalAll
                :detail="engineerArr"
                type="engineer"
                :engineer-id="engineerArr?.id"
              ></VisitInternalAll>
            </v-tab-item>

            <v-tab-item v-if="getPermission('job:view')" :value="'job-summary'">
              <Tickets
                :detail="engineerArr"
                :type_id="engineerArr.id"
                type="engineer"
              ></Tickets>
            </v-tab-item>
            <v-tab-item :value="'leave-update'">
              <LeaveUpdateEngineer
                :detail="engineerArr"
                :engineer-id="engineerArr.id"
              ></LeaveUpdateEngineer>
            </v-tab-item>

            <v-tab-item :value="'attachments'">
              <div class="service-detail-height">
                <AdditionalDocuments
                  :type-id="engineerArr.id"
                  class="mx-4"
                  type-text="Technician Attachments"
                  :type="29"
                  :extensive="false"
                ></AdditionalDocuments>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </template>
    </DetailTemplate>
    <Dialog :common-dialog="resetPasswordDialog">
      <template v-slot:title>Reset Password</template>
      <template v-slot:body>
        <PasswordUpdate
          ref="passwordUpdateRef"
          v-on:password:updated="resetPasswordSuccess"
          v-on:loading="($event) => (resetPasswordLoading = $event)"
          hideInternalBtn
        ></PasswordUpdate>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          :loading="resetPasswordLoading"
          @click="resetPassword()"
        >
          update Password
        </v-btn>
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          :disabled="resetPasswordLoading"
          @click="resetPasswordDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>

    <Dialog :common-dialog="signatureDialog">
      <template v-slot:title>Signature</template>
      <template v-slot:body>
        <v-container class="ml-4">
          <v-layout :key="attachment_url" style="max-width: 50%">
            <v-flex md6>
              <v-img
                contain
                v-if="attachment_url"
                :lazy-src="$defaultImage"
                :src="attachment_url"
              ></v-img>
              <v-img
                v-else
                contain
                :lazy-src="$defaultImage"
                :src="$defaultImage"
              ></v-img>
              <br />
              <v-btn
                v-on:click="select_file()"
                color="cyan white--text"
                class="custom-bold-button"
                label
                >Change</v-btn
              >
              <v-file-input
                ref="select-file"
                class="d-none"
                accept="image/png, image/jpeg, image/jpg"
                v-model="signature"
                dense
                v-on:change="on_file_change($event)"
                hide-details
              ></v-file-input>
            </v-flex>
            <v-flex md6> </v-flex>
          </v-layout>
          <br />
          <v-layout
            class="signature-pad"
            id="signature-pad"
            style="max-width: 50%"
          >
            <div
              ref="engineer_signature_div"
              style="width: 280px"
              class="custom-border-grey-dashed signature-pad-wrap"
            >
              <canvas
                ref="engineer_signature"
                style="width: 280px; height: 120px !important"
              ></canvas>
            </div>
            <hr />
            <div style="position: relative">
              <v-btn
                style="position: absolute; bottom: 0px; right: -14px"
                content="Click here to clear signature"
                v-tippy="{ arrow: true, placement: 'top' }"
                icon
                small
                v-on:click="customer_signature.clear()"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </div> </v-layout
          ><br />
          <v-layout style="max-width: 50%">
            <v-btn
              v-on:click="submitSignature"
              color="cyan white--text"
              class="custom-bold-button"
              :disabled="valid_signature"
              >Submit</v-btn
            >
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <!-- <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          :loading="resetPasswordLoading"
          @click="resetPassword()"
        >
          update Password
        </v-btn> -->
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          :disabled="signatureDialogLoading"
          @click="signatureDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :commonDialog="eSignatureDialog">
      <template v-slot:title>Email Signature</template>
      <template v-slot:body>
        <v-container>
          <TinyMCE v-model="email_signature" />
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          v-on:click="submitEmailSignature"
          color="cyan white--text"
          class="custom-bold-button"
          :disabled="!email_signature"
          >Submit</v-btn
        >
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          @click="eSignatureDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import Delete from "@/view/pages/partials/Delete.vue";
import PersonalInfo from "@/view/pages/engineer/detail/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/detail/Emergency-Contact";
import EngineerAddress from "@/view/pages/engineer/detail/Engineer-Address";
import Color from "@/view/pages/engineer/detail/Color";
import EngineerMixin from "@/core/lib/engineer/engineer.mixin";
import Status from "@/view/pages/partials/Status.vue";
//import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import PasswordUpdate from "@/view/pages/engineer/PasswordUpdate";
import LeaveUpdateEngineer from "@/view/pages/engineer/Leave-Update-Engineer";
import ObjectPath from "object-path";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
// import Tickets from "@/view/pages/engineer/Tickets";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import VisitInternalAll from "@/view/pages/partials/Detail/VisitInternalAll.vue";

//import DeliveryListingTemplate from "@/view/pages/delivery/Visit-Listing-Template";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";
import { PATCH, PUT, UPLOAD, GET } from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import LoginLog from "@/view/pages/user/detail/LogsLoginActivity";

export default {
  name: "engineer-detail",
  title: "Engineer",
  mixins: [CommonMixin, ValidationMixin, EngineerMixin],
  data() {
    return {
      signatureDialog: false,
      detail: new Object(),
      loginLogs: [],
      engineer: 0,
      panel: 0,
      engineerTab: null,
      pageLoading: true,
      deleteDialog: false,
      resetPasswordDialog: false,
      resetPasswordLoading: false,
      signatureDialogLoading: false,
      signatureDialogSuccess: false,
      email_signature: null,
      eSignatureDialog: false,
      attachment_url: null,
      signature: null,
      engineerArr: {},
    };
  },
  components: {
    Tickets,
    Status,
    VisitInternalAll,
    DetailTemplate,
    // DeliveryListingTemplate,
    PersonalInfo,
    EmergencyContact,
    EngineerAddress,
    Color,
    PasswordUpdate,
    LeaveUpdateEngineer,
    AdditionalDocuments,
    InternalHistoryDetail,
    Dialog,
    TinyMCE,
    LoginLog,
    //FileTemplate
  },
  watch: {
    engineerTab(param) {
      if (param == "siganture") {
        let _this = this;
        setTimeout(function () {
          _this.initSignature();
        }, 500);
      }
    },
    signatureDialog(param) {
      if (param) {
        let _this = this;
        setTimeout(function () {
          _this.initSignature();
        }, 500);
      }
    },
  },
  methods: {
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    update_signature() {
      this.$store
        .dispatch(PATCH, {
          url: `user/${this.engineerArr?.user?.id}/signature/update`,
          data: { signature: this.attachment_url },
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }
      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(PUT, {
          url: `user/${this.engineerArr?.user?.id}/signature/update`,
          data: {
            customer: _this.customer_signature.toDataURL(),
          },
        })
        .then((data) => {
          this.attachment_url = data?.data?.url;
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.customer_signature.clear();
        });
    },
    submitEmailSignature() {
      const _this = this;
      this.signatureDialogLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: `user/${this.engineerArr?.user?.id}/email-signature/update`,
          data: {
            customer: this.email_signature,
          },
        })
        .then(() => {
          this.eSignatureDialog = false;
          this.signatureDialogLoading = false;
          /* this.getUser(); */
        })
        .catch(() => {})
        .finally(() => {
          _this.getEngineer();
        });
    },
    emailSignature() {
      if (!this.engineerArr?.user?.email_signature) {
        this.email_signature = null;
      } else {
        this.email_signature = this.engineerArr?.user?.email_signature;
      }
      this.eSignatureDialog = true;
    },
    loadContent() {
      this.attachment_url = this.engineerArr?.user?.signature ?? null;
    },
    initSignature() {
      const _this = this;
      if (_this.signatureDialog) {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["engineer_signature"];
          let ccanvasDiv = _this.$refs["engineer_signature_div"];
          // let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = 280; //cparentDiv.offsetWidth / 2;

          ccanvas.setAttribute("width", cparentWidth);

          ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");

          _this.customer_signature = new SignaturePad(ccanvas);
        });
      }
    },
    resetPassword() {
      this.$refs.passwordUpdateRef.onSubmit();
    },
    resetPasswordSuccess() {
      this.resetPasswordDialog = false;
    },
    deleteEngineer() {
      const _this = this;
      if (!_this.$refs.engineerDeleteForm.validate()) {
        return false;
      }
    },
    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.doc_file.url, "_blank");
          break;
      }
    },
    getLoginActivity() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: `login-activity/${_this.engineerArr?.user?.id}`,
        })
        .then(({ data }) => {
          _this.loginLogs = data || {};
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    const _this = this;
    _this
      .getEngineer()
      .then((response) => {
        _this.engineerArr = response;
        _this.loadContent();
        _this.initSignature();
        _this.getLoginActivity();
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Technician", route: "engineer" },
          { title: "Detail" },
          { title: response.barcode },
        ]);
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  },
  created() {
    const _this = this;
    _this.engineer = _this.$route.params.id;
    if (!_this.engineer || _this.engineer <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
    getTitle() {
      let result = [];
      if (this.engineerArr && this.engineerArr.customer) {
        result.push(this.engineerArr.customer.display_name);
      }
      if (
        this.engineerArr &&
        this.engineerArr.customer &&
        this.engineerArr.customer.default_person
      ) {
        result.push(this.engineerArr.customer.default_person.display_name);
      }
      if (this.engineerArr && this.engineerArr.barcode) {
        result.push(this.engineerArr.barcode);
      }
      return result.join(" • ");
    },
    countryName() {
      let result = "";
      if (this.lodash.isEmpty(this.engineerArr.country) === false) {
        result = this.engineerArr.country.name;
      }
      return result;
    },
    getStatus() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.activated
        : false;
    },
    getBarcode() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.barcode
        : false;
    },
    getRouteParam() {
      let result = { customer: 0, id: 0 };
      if (this.lodash.isEmpty(this.engineerArr) === false) {
        if (this.engineerArr.id) {
          result.id = this.engineerArr.id;
        }
        if (this.engineerArr.customer) {
          result.customer = this.engineerArr.customer.id;
        }
      }
      return result;
    },
  },
};
</script>
